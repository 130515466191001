import React from 'react';
import { Link } from 'gatsby';
import ContentPageLayout from '../../components/ContentPageLayout';
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import SCButton from "../../components/buttons/SCButton";


const linkExpired = () => {


    return (
        <ContentPageLayout>
            <Seo title="El enlace ha expirado" />
            <div>
                <div className="flex relative" style={{ height: 240, width: "100%" }}>
                    <StaticImage alt="banner image" src="../../images/banner_superior.png" style={{ width: "100%", height: "100%", objectFit: "cover" }}></StaticImage>
                    <div className="absolute flex p-5 items-end h-full">
                        <h1 className="text-white"></h1>
                        <p className="text-white"></p>
                    </div>
                </div>
            </div>

            <div className="p-12 md:w-96 md:p-0 md:mt-7  pb-24 mx-auto my-0">
                <div className="w-full h-full m-0 flex flex-col" >
                    <h1 className='text-center'>El enlace ha expirado</h1>
                    <p className='text-justify'>
                        Solicita nuevamente el proceso de recuperar contraseña para generarte un nuevo enlace.
                    </p>
                    <div className='mt-8 mx-auto' style={{ width: '281px', height: '44px' }}>
                        <Link to="/"> <SCButton>Confirmar</SCButton> </Link>
                    </div>
                </div>
            </div>
        </ContentPageLayout>
    )
}

export default linkExpired
